<template>

    <div class="column flex-auto" style="margin-bottom:0">
      <div class="border-bottom padding-bottom-10 bold">我的订单</div>
      <div class="order-class flex-row align-items-center justify-around text-align-center margin-tb-20">
        <div v-for="(item,index) in orderType" :key="index" :class="{'link':true, 'active':curType==item.value}" @click="curType=item.value;init()">
          <el-badge :max="99" :value="item.value==1?num.dtq:item.value==2?num.fjz:item.value==3?num.yck:''" :hidden="item.value==0||item.value==4||(item.value==1&&!num.dtq)||(item.value==2&&!num.fjz)||(item.value==3&&!num.yck)">
            <img :src="item.img" height="30"/>
            <div>{{item.name}}</div>
          </el-badge>
        </div>

      </div>
      <div class="table-vertical-top table-cell-padding-none"  ref="main">
        <el-table
          v-if="listInfo.list.length"
          :data="listInfo.list"
          stripe
          border
          :show-header="false"
          style="width: 100%"
        >
          <el-table-column label="订单信息"  min-width="200">
            <template slot-scope="props">
              <div class="flex-row align-items-center font-size-12 padding-lr-10" style="height: 30px;">
                <div class="margin-right-20">订单号：<span class="bold">{{props.row.order_no}}</span></div>
                <div>{{props.row.create_time}}</div>
              </div>
              <div class="padding-10">
                <div class="flex-row link margin-bottom-5" v-for="(item,index) in props.row.product_list.slice(0,2)" :key="index" @click="$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:item.spid,hd_id:item.hd_id})},blank:true})">
                  <div><el-image class="item-pic border" :src="item.img_url" fit="contain">
                    <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                      <img src="@/assets/images/no-pic.png" height="40px"/>
                    </div>
                  </el-image></div>
                  <div class="flex-auto margin-left-10 line-height-medium">
                    <div class="">{{item.spmch}}</div>
                    <div class="flex-row justify-between">
                      <div class="color-sub font-size-12">{{item.shpgg}}</div>
                      <div class="color-sub font-size-12">
                        <span>￥19.9</span>
                        <span class="margin-left-5">X{{item.sp_buy_sl}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="props.row.product_list.length>2" class="flex-row justify-end"><div class="link-text" @click="$root.jump({path:'/orderDetail',query:{q:crypto.encrypt({order_id:props.row.order_id})}})">更多商品</div></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="收货人">
            <template slot-scope="props">
              <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">收货人</div>
              <div class="padding-10">{{props.row.shr}}</div>
            </template>
          </el-table-column>
          <el-table-column label="客户名称">
             <template slot-scope="props">
              <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">客户名称</div>
              <div class="padding-10">{{props.row.khmc}}</div>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="150">
            <template slot-scope="props">
              <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">金额</div>
              <div class="padding-10">
                <div>实付：<span class="color-orange">￥{{props.row.sfje}}</span></div>
                <div class="color-sub font-size-12">商品合计：<span>￥{{props.row.zje}}</span></div>
                <div class="color-sub font-size-12"><span>运费：￥{{props.row.yf}}</span></div>
                <div class="color-sub font-size-12">优惠：<span>￥{{props.row.hyje}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120" align="center">
            <template slot-scope="props">
              <div class="flex-row align-items-center justify-center font-size-12 padding-lr-10 bold text-nowrap" style="height: 30px;">
                <span class="color-sub">订单状态：</span>
                <span :class="{'color-red':props.row.order_state==1,'color-orange':props.row.order_state==2,'color-blue':props.row.order_state==3,'color-green':props.row.order_state==4,'color-sub':props.row.order_state==5}"
                >{{props.row.order_state==1?'待提取':props.row.order_state==2?'分拣中':props.row.order_state==3?'已出库':props.row.order_state==4?'已送达':'已取消'}}</span>
              </div>
              <div class="padding-10">
                <div class="font-size-12">收货回执审核中</div>
                <div class="margin-top-5"><el-button size="mini" round style="width:80px;" @click="$root.jump({path:'/orderDetail',query:{q:crypto.encrypt({order_id:props.row.order_id})}})">订单详情</el-button></div>
                <template v-if="props.row.order_state!=5">
                  <div class="margin-top-5" v-if="props.row.order_state!=4&&props.row.order_state!=5">
                    <el-button type="success" size="mini" round style="width:80px;" @click="activeOrder=props.row;SignShow=true">确认收货</el-button>
                  </div>
                  <!-- <div class="margin-top-5" v-if="props.row.psfs=='自提'"><el-button type="primary" size="mini" round style="width:80px;">自提码</el-button></div> -->
                  <div class="margin-top-5" v-if="props.row.order_state==4"><el-button type="primary" size="mini" round style="width:80px;">评价</el-button></div>
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="listInfo.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
        <div v-if="listInfo.noMore&&listInfo.list.length" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
        <el-empty v-if="!listInfo.list.length&&listInfo.page==1&&!listInfo.loading" description="暂无数据"></el-empty>
      </div>
      <el-dialog custom-class="custom-dialog" title="确认收货"  :closeOnClickModal="false" :visible.sync="SignShow" :append-to-body="true" width="980px" top="10vh">
        <div>
          <Sign :orderId="activeOrder.order_no" :mhj="(activeOrder.product_list||[]).some(v=>v.is_mhj)" @success="init();SignShow=false;"></Sign>
        </div>
      </el-dialog>
    </div>

</template>

<script>
import orderClassImg0 from "@/assets/images/order-status-0.png";
import orderClassImg1 from "@/assets/images/order-status-1.png";
import orderClassImg2 from "@/assets/images/order-status-2.png";
import orderClassImg3 from "@/assets/images/order-status-3.png";
import orderClassImg4 from "@/assets/images/order-status-4.png";
import crypto from "@/common/crypto";
import Sign from "@/components/Sign";
export default {
  components: { Sign },
  name: 'OrderList',
  data() {
    return {
      crypto,
      orderType:[
        {name:'全部订单',value:0,img:orderClassImg0},
        {name:'待提取',value:1,img:orderClassImg1},
        {name:'分拣中',value:2,img:orderClassImg2},
        {name:'已出库',value:3,img:orderClassImg3},
        {name:'已送达',value:4,img:orderClassImg4},
      ],
      curType:0,
      listInfo:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[]
      },
      num:{},
      bodyScrollHeight:0,
      SignShow:false,
      activeOrder:{}
    }
  },
  computed:{

  },
  methods:{
    init(){
      document.removeEventListener("scroll", this.loadMore);
      this.listInfo.noMore=false
      this.listInfo.page=1
      this.listInfo.list=[]
      this.getList()
    },
     //获取列表
    getList(){
      this.listInfo.loading=true
      let params= {
        ...this.$store.state.basicParams,
        order_type:this.curType,
        page:this.listInfo.page,
        page_size:this.listInfo.pageSize,
      }
      this.$instance.get('/order/get_order_list',{params})
      .then(res=>{
        this.listInfo.loading=false
        if(res.data.code==0){
          this.listInfo.list=this.listInfo.list.concat(res.data.data.list)
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          //是否全部加载完成
          if(res.data.data.list.length<this.listInfo.pageSize){
            this.listInfo.noMore=true
          }else{this.listInfo.page+=1}
          //如果有更多增加滚动加载侦听
          if(this.listInfo.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.listInfo.loading=false
      }); 
    },
    //加载更多
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.main,
        allow:!this.listInfo.loading&&!this.listInfo.noMore,
        offset:0,
        callBack:this.getList,
      })
    },
     //获取角标
    getNum(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/order/get_order_ck',{params})
      .then(res=>{
        if(res.data.code==0){
          this.num=res.data.data
        }
      })
      .catch(()=> {
      }); 
    },
  },
  created(){
    if(this.$route.query.type){
      this.curType=this.$route.query.type
    }
    this.getNum()
    this.getList()
  },
  mounted(){ 
   
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .order-class>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
  }
  .order-class>div.active{
    background: #f0f0f0;
    border-radius: 5px;
  }
 .item-pic{
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }
</style>
<style>

</style>

<template>
  <div class="user-home flex-auto flex-column">
    <div class="flex-row">
      <div class="welcome flex-column align-items-center justify-center">
        <el-avatar :size="50" icon="el-icon-user-solid" :src="avatar"></el-avatar>
        <div class="flex-row align-items-center  justify-center margin-top-20">
          <div class="font-size-20 bold">Hi,{{ $store.state.loginRes.user_name }}</div>
          <el-tag v-if="$store.state.loginRes.audit_state==2" type="success" size="mini" class="margin-left-10">已认证</el-tag>
        </div>
        <div class="flex-row margin-top-20">
          <div class="custom-btn link margin-right-20" color="gray">我的认证</div>
          <div class="custom-btn link" color="gray" @click="$root.jump({path:'/user/address'})">地址管理</div>
        </div>
        <!-- <div class="flex-row align-items-center justify-center padding-tb-10" style="position: absolute; left: 0; bottom: 0; width: 100%; background: rgba(0,0,0,0.3);">
          <div class="margin-right-20">有8笔待确认订单</div>
          <div class="custom-btn" style="cursor: pointer;" color="orange" @click="$root.jump({path:'/mall/prodList'})">继续购买</div>
        </div> -->
      </div>
      <div class="column flex-auto flex-column" style="margin:15px 0; padding:0; border-radius: 0 10px 10px 0/0 10px 10px 0;">
        <div class="border-bottom padding-tb-10 padding-lr-20">
          我的授信
          <el-popover placement="right" width="350" trigger="hover">
            <div class="line-height-medium">
              普通总额度=基础额度+临时额度
            </div>
            <span slot="reference"><i class="el-icon-info link font-size-14 margin-left-5"></i></span>
          </el-popover>
        </div>
        <div class="flex-row align-items-center justify-between flex-auto">
          <div class="text-align-center padding-lr-40">
            <div class="font-size-24 color-orange bold">{{credit.general_all_limit}}</div>
            <div class="font-size-12">普通总额度</div>
          </div>
          <div class="text-align-center padding-lr-40">
            <div class="font-size-24 color-orange bold">{{credit.general_available_limit}}</div>
            <div class="font-size-12">普通可用额度</div>
          </div>
          <div class="text-align-center padding-lr-40">
            <div class="font-size-24 color-orange bold">{{credit.mhj_all_limit}}</div>
            <div class="font-size-12">麻黄碱额度</div>
          </div>
          <div class="text-align-center padding-lr-40">
            <div class="font-size-24 color-orange bold">{{credit.mhj_available_limit}}</div>
            <div class="font-size-12">麻黄碱可用额度</div>
          </div>
        </div>
      </div>
    </div>
    <OrderList class="margin-top-20"></OrderList>
  </div>
</template>

<script>
import avatar from "@/assets/images/default-photo.png";
import OrderList from './OrderList.vue'
export default {
  components: {OrderList },
  name: 'UserHome',
  data() {
    return {
     avatar,
     credit:{}
    }
  },
  computed:{

  },
  methods:{
    //获取授信额度
    getCredit(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/user/my_credit',{params})
      .then(res=>{
        if(res.data.code==0){
          this.credit=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    
  },
  created(){
    this.getCredit()
  },
  mounted(){ 
   
  },
  beforeDestroy() {
  
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .welcome{
  flex:0 0 300px;
  height: 200px;
  background: url('../../assets/images/user-welcome.jpg') center no-repeat;
  background-size:cover;
  color:#fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
 }
</style>
<style>

</style>
